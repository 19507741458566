<template>
    <a  v-bind:href="'#' + scrollId">
        <!--Wrapper are required to prevent overlapping from rotation-->
        <div class="wrapper-outer">
            <div v-if="!isTop" class="triangle2"/>           
            <div class="wrapper-inner">
                <div class="vertical-text">
                    <div class="rect" >          
                        <p class="text" >{{text}}</p>
                    </div>  
                </div>
            </div>
            <div class="triangle"></div>
        </div>
    </a>
</template>

<script>
    export default {
    name: 'LargeNavbarElement',
    props: ['text', 'scrollId', 'isTop'],
}

</script>

<style lang="sass" scoped>   
    .triangle 
        width: 0
        height: 0
        border-top: 40px solid #213971
        border-right: 40px solid transparent

    .triangle2
        transform: rotate(180deg) 
        width: 0
        height: 0
        border-top: 40px solid #213971
        border-right: 40px solid transparent
        
    .rect
        width: inline
        height: 40px
        background: #213971

    .wrapper-outer
        display: table
        margin-bottom: -30px

    .wrapper-inner
        padding: 49.5% 0
        height: 0

    .vertical-text
        display: block
        transform-origin: top left
        transform: rotate(90deg) translate(0, -100%)
        margin-top: -50%
        white-space: nowrap

    .text   
        padding-left: 10px
        padding-top: 10px
        font-size: 15px
        color: white
        font-family: sans-serif
        
    a
        text-decoration: none
</style>