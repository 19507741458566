<template>
    <div class="pt-2 menu" >
        <LocaleSwitcher/>
        <div class="entries">
            <LargeNavbarElement :text="$t('exampleProjects.exampleProject')" scrollId="exampleProjects" isTop=True></LargeNavbarElement>
            <LargeNavbarElement :text="$t('cv.resume')" scrollId="cv" ></LargeNavbarElement>
            <LargeNavbarElement :text="$t('main.progLang')" scrollId="programmingLanguages" ></LargeNavbarElement>
            <LargeNavbarElement :text="$t('main.miscellaneous')" scrollId="miscellaneous" ></LargeNavbarElement>
        </div>
    </div>
   
</template>

<script>
import LocaleSwitcher from "../LocaleSwitcher.vue";
import LargeNavbarElement from "./LargeNavbarElement.vue";

export default {
    name: "LargeNavbar",
    components: { LargeNavbarElement, LocaleSwitcher }
}
</script>

<style lang="sass" scoped>
    .entries
        padding: 10px
    .menu
        margin-bottom: 150px
</style>
