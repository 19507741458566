<template>
    <div class="row justify-content-center">
        <a href="https://www.linkedin.com/in/markus-schottenhammer-2547151aa/" target="_blank" rel="noopener noreferrer" class="col-4">
            <img src="@/assets/linkedIn.png" alt="LinkedIn" class="socialMediaIcon">
            <p>LinkedIn</p>
        </a>
        <a href="https://www.xing.com/profile/Markus_Schottenhammer/cv" target="_blank" rel="noopener noreferrer" class="col-4">
            <img src="@/assets/xing.png" alt="Xing" class="socialMediaIcon">
            <p>Xing</p>
        </a>
        <a href="https://github.com/schotti1001" target="_blank" rel="noopener noreferrer" class="col-4">
            <img src="@/assets/github-logo.png" alt="GitHub" class="socialMediaIcon">
            <p>GitHub</p>
        </a>
        <a href="mailto:markus@bavarianbits.de" target="_blank" rel="noopener noreferrer" class="col-4">
            <img src="@/assets/mail.png" alt="Mail" class="socialMediaIcon">
            <p>E-Mail</p>
        </a>
    </div>
</template>

<script>
    export default {
    name: "ContactLinks"
    }
</script>

<style lang="sass" scoped>
    a
        text-decoration: none
        color: inherit
        
    .socialMediaIcon
        height: 35px
        margin-bottom: 10px

</style>