<template>
    <div>
        <h1 class="mb-5" >{{ $t('miscellaneous.miscellaneous')}}</h1>
        <div class="row justify-content-center">
            <div  v-for="entry in miscellaneous" :key="entry.id" class="col-md-3 col-sm-6 mb-5">
                <a class="unlink" :href="entry.link">
                    <img class="img pb-4" :src="imgUrl(entry.icon)"/>
                    <p ><span class="title">{{ $t(entry.title)}},</span> {{entry.issuedBy}}, {{entry.time}}</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    var images = require.context('@/assets', false, /^\.\//)

    export default {
    name: "Miscellaneous",
    data() { return {
        miscellaneous: [
                    { id: "1", title: 'miscellaneous.scholarshipVector', time: "Feb. 2022 - Feb. 2023",  issuedBy: "Vector Informatik GmbH", icon: "reward.png", link:"https://jobs.vector.com/de/du-bist/studierende/stipendienprogramm"},
                    { id: "2", title: 'miscellaneous.studentRepresentation', time: "Okt. 2021 - Sep. 2022",  issuedBy: "OTH Regensburg",  icon:"hochschule.png", link:"https://stuv.othr.de/about/fakultaetsrat/"},
                    { id: "3", title: 'miscellaneous.studentAmbassador', time: "Mai 2021 - Feb. 2024",  issuedBy: "OTH Regensburg", icon:"hochschule.png", link:"https://www.oth-regensburg.de/fakultaeten/informatik-und-mathematik/studiengaenge/bachelor-informatik.html"},
                ]
            }},
    methods: {
    imgUrl: function (path) {
        return images('./' + path)
    }
    }
}
</script>

<style lang="sass" scoped>
    .title
        font-size: 14px
        font-weight: bold
    .img
        text-align: center
        width: 100%
        object-fit: scale-down
        max-height: 80px

    a
        color: inherit
        text-decoration: inherit

        &:hover
            color: #0a58ca

</style>