<template>
    <div>
        <h1 class="mb-5">{{$t('progLang.progLang')}}</h1>
        <div class="row justify-content-center">
            <div  v-for="language in languages" :key="language.id"  class="progLang col-md-3 col-sm-6 mb-5 unlink">
                <ProgrammingLanguageDetails :title="language.title" :desc="language.desc" :id="language.id" :preview="language.preview" :percentage="language.percentage"/>
            </div>
            <div class="progLang col-md-3 col-sm-6 mb-5">
                <b-button v-b-modal:[`progLang-more`] key="progLang-more" class="unlink">
                    <img src="@/assets/more.png" class="img mb-3"/>
                    <h4 class="pt-0 ">{{$t('progLang.moreLang')}}</h4>
                    <p class="pt-2">{{$t('progLang.clickForMore')}}</p>
                </b-button> 
            </div>
        </div>

        <b-modal :id="`progLang-more`" body-bg-variant="dark" bodyTextVariant='light' size="l" centered scrollable hide-footer hide-header>
            <div class="container">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t('progLang.moreLang')}}</h5>
                    <button type="button" class="btn-close btn-close-white" data-dismiss="modal"  aria-label="Close" @click="$bvModal.hide(`progLang-more`)">
                    </button>
                </div>
                <div class="modal-body">
                    <div v-for="language in moreLanguages" :key="language.id" >
                        <h3 ><img :src="imgUrl(language.img)" class="logo"/>  {{language.title}}</h3>
                        <p>{{ $t(language.text)}}</p>
                    </div>       
                </div> 
            </div>
        </b-modal>
    </div>
</template>

<script>
    import ProgrammingLanguageDetails from './ProgrammingLanguageDetails.vue';
    var images = require.context('@/assets', false, /^\.\//)

    export default {
    name: "ProgrammingLanguages",
    components: {  ProgrammingLanguageDetails },
    data() { return {
                languages: [
                    { id: "1", title: 'C#', preview:"progLang.prevSeeSharp", desc: [{headline: "progLang.edvSchool", text:"progLang.SeeSharpSchool"}], percentage: 90 },
                    { id: "3", title: 'Dart, Flutter, Firebase', preview: "progLang.prevApp", desc: [{headline: "progLang.hobby", text:"progLang.dartFlutterFirebase"}], percentage: 85 },
                    { id: "6", title: 'TypeScript', preview: "progLang.prevApp",  desc: [{headline: "progLang.hobby", text:"progLang.typeScript"}], percentage: 85 },
                    { id: "8", title: 'Web', preview: "progLang.prevWeb",  desc: [{text:"progLang.web"}], percentage: 70 },
                    { id: "2", title: 'Java', preview:"progLang.prevJava",  desc: [{headline: "progLang.edvSchool", text:"progLang.Java"}], percentage: 60 },
                    { id: "5", title: 'Python', preview: "progLang.prevPython", desc: [{headline: "progLang.hobby", text:"progLang.python"}],  percentage: 30 },
                    { id: "7", title: 'Vue', preview: "progLang.prevPortfolio",  desc: [{headline: "progLang.hobby", text:"progLang.portfolioWebsite"}],percentage: 30 },
                ],
                moreLanguages: [
                    { id: "1", title: "C / C++", text: "progLang.c", img:"cPlusPlus.png"},
                    { id: "2", title: "MQTT", text: "progLang.mqtt", img:"mqtt.png"},
                    { id: "3", title: "PostgreSql", text: "progLang.postgreSql", img:"postgresql.png"},
                    { id: "5", title: "Oracle DB", text: "progLang.oracleDB", img:"oracle.png"},
                    { id: "6", title: "Maria DB", text: "progLang.mariaDB", img:"mariadb.png"},
                    { id: "8", title: "Cloud Firestore", text: "progLang.firestore", img:"firestore.png"},
                    { id: "7", title: "Assembler NASM", text: "progLang.assembler", img:"nasm.png"},
                    { id: "4", title: "Espresso / Gherkin", text: "progLang.espresso", img:"kotlin.png"},
                    //TODO: DW -> Exasol(&Pyramid), CG -> OpenGL(Vertex/FragmentShader Programmierung Einführung),
                    // VS -> GRPC/REST/RMI/Sockets/Microservices,
                ]
            }},
        methods: {
            imgUrl: function (path) {
                return images("./" + path)
            }
        }
}
</script>

<style lang="sass" scoped>
    .unlink
        background-color: inherit !important
        border: none
        &:hover
            color: #0a58ca !important
   
    .img
        text-align: center
        width: 100%
        object-fit: scale-down
        max-height: 62px
    
    .logo
        height: 20px

</style>