import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import VueMq from 'vue-mq'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'
import VueEllipseProgress from 'vue-ellipse-progress';
import VueMeta from 'vue-meta';


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
//TODO: fix displayed progress in sidebar
Vue.use(VueEllipseProgress);
Vue.use(VueMeta);
Vue.mixin({
    methods: {
        limitTextLength(text, length) {
            if (text.length > length) { 
                return text.substring(0, length) + '...';
            }
            return text;
        }
    }
})


new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
