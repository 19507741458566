<template>
    <div>
        <b-button v-b-modal:[`progLang-${id}`] class="unlink">
                    <ProgressItem :text="title" :percentage="percentage" fontSize="20px" :size=60 percentageSize="16px" class="pb-0"></ProgressItem>
                    <p class="pt-0">{{ $t(preview)}}</p>
        </b-button> 

        <b-modal :id="`progLang-${id}`" body-bg-variant="dark" bodyTextVariant='light' size="l" centered scrollable hide-footer hide-header>
            <div class="container">
                <div class="modal-header">
                    <h2 class="modal-title">{{title}}<span class="subtitle"> ({{ $t(preview)}})</span></h2>
                    <button type="button" class="btn-close btn-close-white" data-dismiss="modal"  aria-label="Close" @click="$bvModal.hide(`progLang-${id}`)">
                    </button>
                </div>
                <div class="modal-body ">
                    <div v-for="descElements in desc" :key="descElements.id">
                        <h3 v-if="descElements.headline">{{ $t(descElements.headline)}}</h3>
                        <p>{{ $t(descElements.text)}}</p>
                    </div>
                </div> 
            </div>
        </b-modal>
    </div>
</template>

<script>
    import ProgressItem from '@/components/ProgressItem.vue';

    export default {
    name: 'ProgrammingLanguageDetails',
    props: ['title', 'desc', 'id', 'preview', 'percentage', 'time'],
    components: {  ProgressItem },
}
</script>

<style lang="sass" scoped>
    .unlink
        background-color: inherit !important
        border: none
        &:hover
            color: #0a58ca !important
    .subtitle
        font-size: 14px
</style>