<template>
    <div class="timeline" :class="isSchool ? 'left': 'right'">
        <div class="card">
            <div class="card-body p-4">
                <div class="d-flex justify-content-between">
                    <div class="row">
                        <h3 class="entryName col-xl-9 col-sm-12">{{title}} </h3> 
                    </div>
                    <span v-if="isSchool" class="badge float-right badge-secondary school-badge">{{ t('cv.school')}}</span>
                    <span v-else class="badge float-right badge-secondary work-badge">{{ t('cv.work')}}</span>
                </div>
                    <h6 class="time ">{{ t('cv.at')}} {{company}}, <span class="company">{{time}}</span></h6>
                <p class="mb-0">{{ limitTextLength(t(desc), 80)}}</p>
                <b-button type="button" class="customButton" v-b-modal:[`cvElement-${id}`]>
                    {{ $t('cv.readMore')}}
                </b-button>
                <!--<button type="button" class="btn btn-primary">Certificate</button>-->
            </div>
        </div>

        <b-modal :id="`cvElement-${id}`" body-bg-variant="dark" bodyTextVariant='light' size="xl" centered scrollable hide-footer hide-header>
            <div class="container">
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" class="btn-close btn-close-white" data-dismiss="modal"  aria-label="Close" @click="$bvModal.hide(`cvElement-${id}`)">
                    </button>
                </div>
                <div class="modal-body row">
                    <div class="main col-md-10">
                       <p class="time">Zeitraum: {{time}}</p>
                        <p>{{ t(desc)}}</p>

                    </div>
                    <div class="sidebar col-md-2">
                     <h3>Genutze Sprachen / Technologien</h3>
                     <ul>
                            <li v-for="technology in tech" :key="technology">
                                {{technology}}
                            </li>
                        </ul>
                    </div>
                </div> 
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'CvElement',
        props: ['id', 'title', 'tech', 'desc', 'company', 'type', 'time'],
        computed: {
            isSchool() {
                return (this.type === "school") ? true : false;
            }
        },
        methods: {
            t: function (translate) {
                    return this.$i18n.t(translate)
            }
          }        
    }
</script>

<style lang="sass" scoped>
    .customButton
        background-color: #213971
        margin-top: 5px
        height: 35px
    .customButton:hover
            background-color: #0b5ed7
    .time
        font-size: 10px
    .badge
        max-height: 20px
    .school-badge
        background-color: green
    .work-badge
        background-color: blue
    .headline
        display: flex
    .card
        text-align: left
    .entryName
        margin-bottom: 0px
    .company
        font-size: 12px
    .row
        width: 100%
    
    @media screen and (min-width: 574px)
        .badge
            display: none
        
</style>