<template>
    <div class="centered">
        <component
            :is="component"
            :progress=percentage
            :fontSize=percentageSize
            animation="default 1000 0"
            :thickness="2"
            legend
            :size="size??40"
            v-bind="options">
            <span slot="legend-value">
                <span > %</span>
            </span>
        </component>
        <p :style="style" >{{text}}</p>
    </div>
</template>

<script>
    export default {
        name: "ProgressItem",
        props: ["percentage", "text", "fontSize", "size", "percentageSize"],
        data: () => ({
            options: {
            color: "#ffc107",
            "empty-color": "#324c7e",
            thickness: 5,
            "empty-thickness": 3,
            "font-size": "10px",
            "line-mode": "normal",
            animation: "rs 700 1000",
            "font-color": "white",
            },
        }),
        computed: {
            // Keine Ahnung warum die Libary das will
            component() {
                return "vue-ellipse-progress";
            },
            style () {
                return 'fontSize: ' + this.fontSize??'15px';
            }
        },
    };
</script>

<style lang="sass" scoped>
    .centered
        display: inline
</style>