<template>
     <div class="col-lg-4 col-md-6 filter" v-bind:class="getClasses()">
        <b-button class="unlink" v-b-modal:[`modal-${id}`]>
                <img :src="imgUrl(img)" :alt="title" class="img-responsive rounded shadow-lg previewPic">
                <p class="projectName">{{ t(title) }}</p>
                <p class="projectDesc" >{{limitTextLength(t(desc), 80)}}</p>
        </b-button>
        
        <b-modal :id="`modal-${id}`" body-bg-variant="dark" bodyTextVariant='light' size="xl" centered scrollable hide-footer hide-header>
            <div class="container">
                <div class="modal-header">
                    <h5 class="modal-title">{{t(title)}}</h5>
                    <button type="button" class="btn-close btn-close-white" data-dismiss="modal" aria-label="Close" @click="$bvModal.hide(`modal-${id}`)">
                    </button>
                </div>
                <div class="modal-body row">
                    <div class="main col-xl-10">
                        <img :src="imgUrl(img)" class="img-responsive rounded shadow-lg projectImage">
                        <h3>{{t('exampleProjects.challenge')}}</h3>
                        <p class="blockSentence">{{ t(desc)}}</p>
                        <h3>{{t('exampleProjects.solution')}}</h3>
                        <p class="blockSentence">{{ t(solution)}}</p>
                    </div>
                    <div class="sidebar col-xl-2">
                        <h3>{{ $t('exampleProjects.technologies')}}</h3>
                        <ul>
                            <li v-for="technology in tech" :key="technology">
                                {{technology}}
                            </li>
                        </ul>
                        <h3 v-if="link || git">Links</h3>
                        <p v-if="git">GitHub: 
                            <a target="_blank" rel="noopener noreferrer" :href="git" v-snip="{ lines: 1, mode: 'css'}" class="links">{{git}}</a>
                        </p>
                        <p v-if="link">Link: 
                            <a target="_blank" rel="noopener noreferrer" :href="link" v-snip="{ lines: 1, mode: 'css'}" class="links">{{link}}</a>
                        </p>
                        <h3>Created at:</h3>
                        <p>{{createdAt}}</p>
                    </div>
                </div> 
            </div>
        </b-modal>
    </div>
</template>

<script> 
    var images = require.context('@/assets/projectPics', false, /^\.\//)

    export default {
          name: 'ProjectPreview',
          props: ['id', 'title', 'desc', 'solution', 'type', 'tech', 'createdAt', 'git', 'link', 'img'],
          methods: {
            imgUrl: function (path) {
                return images('./' + path)
            },
            t: function (translate) {
                    return this.$i18n.t(translate)
            },
            getClasses: function () {
                var classes = this.type + " ";
                for (var i = 0; i < this.tech.length; i++) {
                    if(this.tech[i]==="C#"){
                        classes += "CSharp ";
                        continue;
                    } 
                    classes += this.tech[i] + " ";
                }
                return classes;
            },
          }
    }
</script>

<style lang="sass" scoped>
    .main
        border-right: 1px solid #ccc

    .sidebar
        max-width: 100%
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
    .links
        width: 100%
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

    .previewPic
        width: 100%
        object-fit: scale-down
        max-height: 150px

    .links
        display: block

    .projectImage
        float: left
        padding-right: 10px
        max-width: 40%

    .blockSentence
        text-align: justify
    .unlink
        color: inherit
        text-decoration: inherit
        background-color: inherit
        border: none

    .projectName
        padding-top: 5px
        font-weight: bold
        font-size: 14px
        margin-bottom: 0px

</style>