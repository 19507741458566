<template>
    <div>
        <h1 class="mb-5">{{$t('main.certificates')}}</h1>
        <div class="row justify-content-center">
            <div  v-for="course in courses" :key="course.id" class="col-md-3 col-sm-6 mb-5">
                <a class="unlink" :href="course.link">
                    <img class="img pb-4" :src="imgUrl(course.icon)"/>
                    <p ><span class="title">{{course.title}},</span> {{course.issuedBy}}, {{course.time}}</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    var images = require.context('@/assets', false, /^\.\//)


    export default {
    name: "CertificatesAndCourses",
    data() { return {
                courses: [
                    { id: "1", title: 'Flutter & Dart - The Complete Guide', time: "Jun. 2021",  issuedBy: "Udemy", icon: "flutter.png", link:"https://www.udemy.com/course/learn-flutter-dart-to-build-ios-android-apps/"},
                    { id: "2", title: 'Fundamentals of Reinforcement Learning', time: "Nov. 2020",  issuedBy: "University of Alberta",  icon:"universityAlberta.png", link:"https://www.coursera.org/learn/fundamentals-of-reinforcement-learning"},
                    { id: "3", title: 'Introduction to Artifical Intelligence with Python', time: "Nov. 2020",  issuedBy: "HarvardX", icon:"cs50ai.png", link:"https://www.edx.org/course/cs50s-introduction-to-artificial-intelligence-with-python"},
                    { id: "4", title: 'KMK-Fremdsprachenzertifikat Stufe III (B2)', time: "Jul. 2017",  issuedBy: "EDV-Schulen des Landkreises Deggendorf", icon:"edvSchule.png", link:"https://www.edvschule-plattling.de/CMS/"},
                    // TOOD: Angular Kurs
                ]
            }},
    methods: {
    imgUrl: function (path) {
        return images('./' + path)
    }
    }
}
</script>

<style lang="sass" scoped>
    .title
        font-size: 14px
        font-weight: bold
    .img
        text-align: center
        width: 100%
        object-fit: scale-down
        max-height: 80px

    a
        color: inherit
        text-decoration: inherit
        &:hover
            color: #0a58ca

</style>