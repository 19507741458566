<template>
  <b-navbar class="sticky-top fixed-top d-flex d-lg-none navbar navbar-static-top " toggleable="lg" type="dark" >

    <b-navbar-toggle class="align-self-end" target="nav-collapse"></b-navbar-toggle>
    <div class="navbar-brand" >
        <LocaleSwitcher></LocaleSwitcher>
    </div>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#exampleProjects">{{$t('exampleProjects.exampleProject')}}</b-nav-item>
        <b-nav-item href="#cv">{{$t('cv.resume')}}</b-nav-item>
        <b-nav-item href="#programmingLanguages">{{$t('main.progLang')}}</b-nav-item>
        <b-nav-item href="#miscellaneous">{{$t('main.miscellaneous')}}</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
    

  </b-navbar>

</template>

<script>
import LocaleSwitcher from './LocaleSwitcher.vue'; 
    export default {
    name: "Navbar",
    components: { LocaleSwitcher }
}
</script>

<style lang="sass" scoped> 
    .navbar
        background-color: inherit
        z-index: 1050
</style>