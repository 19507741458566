<template>
    <div class="container py-5">
        <h1 class="mb-4">{{ $t("cv.resume")}}</h1>
        <div class="row headlines">
            <div class="col-sm-6">
                <h3>{{ $t("cv.education")}}</h3>
            </div>
            <div class="col-sm-6">
                <h3>{{ $t("cv.experience")}}</h3>
            </div>
        </div>
        <div class="main-timeline">
                <CvElement
                v-for="element in displayedElements"
                :key="element.id"
                :id="element.id"
                :title="element.title"
                :time="element.time"
                :tech="element.tech"
                :desc="element.desc"
                :company="element.company"
                :type="element.type"
            />  
        </div>
        <div class="pt-2">
            <button v-if="showLess" type="button" class="btn btn-primary " @click="showLess = !showLess">{{ $t('cv.showMore')}}</button>
            <button v-else type="button" class="btn btn-primary" @click="showLess = !showLess">{{ $t('cv.showLess')}}</button>
        </div>
        <button type="submit" class="btn btn-primary download" @click="downloadCV">{{ $t('cv.downloadCV')}}</button>
  </div>
</template>

<script>
import CvElement from "./CvElement.vue";
import cvPdf from '@/assets/pdf/cvWebsiteSchottenhammer.pdf';

    export default {
    name: "TimeLine",
    components: { CvElement },
    data() { return {
            showLess: true,
            cvElements: [
            {  id: "10", title: "Geschäftsführer",time: "Apr. 2024 - jetzt", tech: ["Dart", "Flutter", "Angular", "Java"],  desc: "cv.bavarianBits",  company: "BavarianBits GmbH", type: "work"},
                { id: "9", title:"Werkstudent", time: "Feb. 2022 - Sep. 2023",tech: ["Elixir", "Phoenix", "Unity", "C#", "GRPC"], desc: "cv.num42WorkingStudent", company: "Number42 GmbH",type:"work" },
                { id: "8", title:"Praxissemester", time: "Sep. 2022 - Jan. 2023",tech: ["Espresso", "Gherkin"], desc: "cv.num42", company: "Number42 GmbH",type:"work" },
                { id: "7", title:"Studentische Hilfkraft", time: "Dez. 2021 - Sep. 2022", tech: ["MQTT", "IOT", "SQL", "Python", "SQLight", "Canva", "Scratch"],desc: "cv.oth_shk", company: "OTH Regensburg", type:"work" },
                { id: "6", title:"Bachelor-Student Informatik", time: "Okt. 2020 - Feb. 2024", tech: ["C", "C++", "Java", "HTML", "CSS", "JavaScript", "PUG", "Bootstrap", "MariaDB"], desc: "cv.oth_stud", company: "OTH Regensburg", type:"school" },
                { id: "5", title:"Fachabitur BOS", time: "Sep. 2019 - Jul. 2020", desc: "cv.fos", tech: ["PHP"],company: "Berufsschule Kelheim", type:"school" },
                { id: "4", title:"Nebenjob Softwareentwicklung", time: "Aug. 2019 - Juni 2020",tech: ["C#", "WPF", "PostgreSQL", "Scrum"], desc: "cv.magna_part", company: "Magna Telemotive GmbH", type:"work" },
                { id: "3", title:"Vollzeit Projektingenieur Softwareentwicklung", time: "Aug. 2018 - Aug. 2019",tech: ["C#", "WPF", "MQTT", "PostgreSQL", "SQLight", "Scrum"], desc: "cv.magna_full", company: "Magna Telemotive GmbH", type:"work" },
                { id: "2", title:"Praktikum Softwareentwicklung", time: "Feb. 2018 - Jul. 2018", tech: ["C#", "WPF", "PostgreSQL", "MQTT", "Scrum"],desc: "cv.magna_appr", company: "Magna Telemotive GmbH", type:"work" },
                { id: "1", title:"Ausbildung zum Fachinformatiker für Anwendungsentwicklung", time: "Sep. 2015 - Feb. 2018", tech: ["C#", "Java", "C", "C++", "HTML", "CSS", "JavaScript", "PHP", "ABAP", "Haskell", "SQL", "MariaDB", "PostgreSQL", "Oracle Database"], desc: "cv.edv_school", company: "EDV Schulen Deggendorf", type:"school" },
            ],
            cvPdfUrl: cvPdf
        }},
    computed:{
        displayedElements() {
            return this.showLess?this.cvElements.slice(0, 3):this.cvElements;
        }
    },
    methods: {
    downloadCV() {
      window.open(this.cvPdfUrl, '_blank');
    }
  }

}
</script>

<style lang="sass" scoped>
.btn
    background-color: #213971
    border: none

/* The actual timeline (the vertical ruler) */
.download
    margin: 10px

.main-timeline
    color: black
    position: relative
    &::after 
        content: ""
        position: absolute
        width: 6px
        background-color: #939597
        top: 0
        bottom: 0
        left: 50%
        margin-left: -3px
    

/* The actual timeline (the vertical ruler) */

/* Container around content */

.timeline 
  position: relative
  background-color: inherit
  width: 50%
  &::after 
    content: ""
    position: absolute
    width: 25px
    height: 25px
    right: -13px
    background-color: #939597
    border: 5px solid #f5df4d
    top: 15px
    border-radius: 50%
    z-index: 1
  
/* The circles on the timeline */

/* Place the container to the left */

.left 
  padding: 0px 40px 20px 0px
  left: 0

/* Place the container to the right */

.right 
  padding: 0px 0px 20px 40px
  left: 50%


/* Add arrows to the left container (pointing right) */
.left::before 
  content: " "
  position: absolute
  top: 18px
  z-index: 1
  right: 30px
  border: medium solid white
  border-width: 10px 0 10px 10px
  border-color: transparent transparent transparent white

/* Add arrows to the right container (pointing left) */

.right 
  &::before 
    content: " "
    position: absolute
    top: 18px
    z-index: 1
    left: 30px
    border: medium solid white
    border-width: 10px 10px 10px 0
    border-color: transparent white transparent transparent
  
  &::after 
    left: -12px
    border: 5px solid #ffc107 !important


/* Fix the circle for containers on the right side */

/* Media queries - Responsive timeline on screens less than 576px wide */
@media screen and (max-width: 574px) 
  /* Place the timelime to the left */
  .headlines
    display: none

  .main-timeline::after 
    left: 31px

  /* Full-width containers */

  .timeline 
    width: 100%
    padding-left: 70px
    padding-right: 25px

    &::before 
      left: 60px
      border: medium solid white
      border-width: 10px 10px 10px 0
      border-color: transparent white transparent transparent

  /* Make sure that all arrows are pointing leftwards */

  /* Make sure all circles are at the same spot */

  .left::after, .right::after 
    left: 18px 
    border: #ffc107 !important

  .left::before 
    right: auto

  /* Make all right containers behave like the left ones */

  .right 
    left: 0%
  

</style>