<template>
    <div class="sidebar d-none d-lg-block">
        <div id="headline">
            <img src="@/assets/Bewerbungsbild.jpg" alt="Avatar" class="avatar">
            <h4>Markus Schottenhammer</h4>
            <p>{{ $t('main.bSc')}}</p>
        </div>
        <div class="facts">
            <div class="d-flex justify-content-between">
                <p class="titleFacts">{{ $t('main.country')}}:</p>
                <p class="fact">{{ $t('main.germany')}}</p>
            </div>
            <div class="d-flex justify-content-between">
                <p class="titleFacts">{{ $t('main.age')}}:</p>
                <p class="fact">25</p>
            </div>
            <hr/>
            <p>Languages:</p>
            <div class="row">
                <ProgressItem :text="$t('main.german')" :percentage=100 percentage-size='12px' class="col-4"></ProgressItem>
                <ProgressItem :text="$t('main.english')" :percentage=90 percentage-size='12px' class="col-4"></ProgressItem>
                <ProgressItem :text="$t('main.italian')" :percentage=10 percentage-size='12px' class="col-4"></ProgressItem>
            </div>
            <hr/>
            <!-- <p>Grades:</p>
            Realschule: 1,7<br/>
            BOS: 1,7
            <hr/> -->

            <p>{{$t('main.contact')}}</p>
            <ContactLinks/>
            
        </div>
    </div>
</template>

<script>
    import ProgressItem from '@/components/ProgressItem.vue';
    import ContactLinks from './ContactLinks.vue';

    export default {
        name: "Sidebar",
        components:{
            ProgressItem,
            ContactLinks
        }
    };
</script>
  
<style lang="sass" scoped>
    .sidebar
        padding-top: 18px
        background-color: #20202a

    .overview
        padding: 0px

    #headline
        background-color: #242430
        padding: 10px 0px

    .titleFacts
        font-weight: bold
    .facts
        margin: 15px

    .avatar
        vertical-align: middle
        width: 100px
        height: 100px
        border-radius: 50%
        margin: 10px
</style>