<template>
        <div class="row wrap">
            <div class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h1 class="gallery-title">{{ $t('exampleProjects.exampleProject')}}</h1>
            </div>

            <div>
                <button class="btn btn-default" @click="filterProjects(['all'])">{{ $t('exampleProjects.all')}}</button>
                <button class="btn btn-default" @click="filterProjects(['work'])">{{ $t('exampleProjects.work')}}</button>
                <button class="btn btn-default" @click="filterProjects(['school'])">{{ $t('exampleProjects.school')}}</button>
                <button class="btn btn-default" @click="filterProjects(['private'])">{{ $t('exampleProjects.private')}}</button>
            </div>
            <div>
                <button class="btn btn-default" @click="filterProjects(['CSharp'])">C#</button>
                <button class="btn btn-default" @click="filterProjects(['Java'])">Java</button>
                <button class="btn btn-default" @click="filterProjects(['Dart', 'Flutter', 'Kotlin'])">Apps</button>
                <button class="btn btn-default" @click="filterProjects(['JavaScript', 'Vue', 'Angular', 'Phoenix', 'Thymeleaf', 'HTML'])">Web</button>

            </div>
            <ProjectPreview
                v-for="project in projects"
                :key="project.id"
                :id="project.id"
                :title="project.title"
                :desc="project.desc"
                :solution="project.solution"
                :type="project.type"
                :tech="project.tech"
                :createdAt="project.createdAt"
                :git="project.git"
                :link="project.link"
                :img="project.img"
            />
        </div>
</template>


<script>
    import $ from 'jquery';
    import ProjectPreview from '@/components/ExampleProjects/ProjectPreview.vue'

    export default {
        name: 'ExampleProjects',
        methods: { 
            filterProjects(filterName) {
                $('.filter').not('.' + filterName).hide('1000');

                for (var i = 0; i < filterName.length; i++) {
                    var filter = filterName[i];
            
                    if(filter == "all")
                    {
                        $('.filter').show('1000');
                    }
                else
                {
                    $('.filter').filter('.' + filter).show('1000');
                }
                }
                
            }
        },
        data() { return {
                projects: [
                    // TODO: Anzahl limitieren -> Mehr laden Button
                    { id: "4", title: 'projects.portfolioWebsite.title', desc: "projects.portfolioWebsite.desc", solution:"projects.portfolioWebsite.solution", type:"private", tech: ["Vue", "Bootstrap", "SASS"], createdAt:"2022", git: "https://github.com/schotti1001/PersonalWebsite", img: "portfolioWebsite.png" },
                    { id: "7", title: 'projects.mrmrshomes.title', desc: "projects.mrmrshomes.desc", solution:"projects.mrmrshomes.solution", type:"work", tech: ["Phoenix", "Elixir", "C#", "GRPC"], createdAt:"2023", link: "https://www.mrmrshomes.de/", img: "mrmrshomes.png" },
                    { id: "8", title: 'projects.hammKliniken.title', desc: "projects.hammKliniken.desc", solution:"projects.hammKliniken.solution", type:"work", tech: ["Kotlin", "Espresso", "Gherkin"], createdAt:"2023", link: "https://www.hamm-kliniken.de/onkologische-rehabilitation/nach-der-reha/hamm-kliniken-app", img: "hammKliniken.png" },
                    { id: "5", title: 'projects.achivementRideWithMe.title', desc: "projects.achivementRideWithMe.desc", solution:"projects.achivementRideWithMe.solution", type:"private", tech: ["Dart", "Flutter", "Firebase", "Firestore", "TypeScript"], createdAt:"2021", link: "https://ridewithme.app/",  img: "AchivementsRideWithMe.jpeg" },
                    { id: "10", title: 'projects.recipes.title', desc: "projects.recipes.desc", solution:"projects.recipes.solution", type:"private", tech: ["Angular", "TypeScript", "NgRx", "Firebase", "REST"], createdAt:"2023", img: "angularRecipesManager.jpg" },
                    { id: "6", title: 'projects.ihk.title', desc: "projects.ihk.desc", solution: "projects.ihk.solution", type:"school", tech: [".NET", "C#", "WPF", "Java"], createdAt:"2018", link: "https://schottenhammer.com/documents/Projektdokumentation.pdf",  img: "IHKAbschlussarbeit.png" },
                    { id: "9", title: 'projects.winewizard.title', desc: "projects.winewizard.desc", solution:"projects.winewizard.solution", type:"school", tech: ["Spring Framework", "Java", "Java EE", "JPA", "Thymeleaf", "H2 database", "API"], createdAt:"2023", img: "wineWizard.jpg" },
                    { id: "1", title: 'projects.websiteKoller.title', desc: "projects.websiteKoller.desc", solution: "projects.websiteKoller.solution", type:"private", tech: ["HTML", "CSS", "JavaScript"], createdAt: "2017", link: "https://www.auto-koller.com", img: "Koller.png" },
                    { id: "2", title: 'projects.listicleRegensburg.title', desc: "projects.listicleRegensburg.desc", solution: "projects.listicleRegensburg.solution", type:"school", tech: ["HTML", "CSS"], createdAt: "2021", git: "https://github.com/schotti1001/WebpageRegensburg", link: "https://schottenhammer.com/projects/listicle/", img: "ListicleRegensburg.png" },
                    { id: "3", title: 'projects.finance.title', desc: "projects.finance.desc", solution:"projects.finance.solution", type:"school", tech: ["PUG", "JavaScript", "PostgreSQL", "Bootstrap"], createdAt:"2021", git: "https://github.com/schotti1001/FinanceProject", img: "Finance.png" },
                   //TODO: trainings app
                   //TODO: Bachelorarbeit
                   //TODO: ARTNER RechnungsManager
                   //TODO: Website Panndorf
                   //TODO: Website Immo Büchl
                ]
            }},
        components: {
            ProjectPreview
        }
    }
</script>

<style lang="sass" scoped>
    .wrap
        width: 100%
    .btn
        color: white
        
    .gallery_product
        max-width: 100%
</style>