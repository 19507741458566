<template>
    <div>
        <button type="button" class="btn" @click="switchLocale('en')"><img class="flag" src="@/assets/flag_us.png" alt="Englisch"/></button>
        <button type="button" class="btn" @click="switchLocale('de')"><img class="flag" src="@/assets/flag_ger.png" alt="German"/></button>
    </div>
</template>

<script>
    export default {
      name: 'LocaleSwitcher',
      methods: { 
        switchLocale(locale) {
          if (this.$i18n.locale !== locale) {
            this.$i18n.locale = locale;
          }
        }
      },
    }
</script>

<style lang="sass" scoped>
    .flag
        width: 30px
        height: 30px


</style>