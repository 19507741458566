<template>
  <div class="home">
    <div class="container-md">
        <Navbar/>
        <div class="row sticky-top gx-5"> 
            <div class="col-lg-3">
                <Sidebar class="sticky-top"/>
            </div>

            <div class="col-lg-9 col-md-12 main" >
                <!--Nested row to get ~0.5 col width for sidemenu-->
                <div class = "row">
                    <div class="col-lg-11 ">
                        <img class="banner rounded" src="@/assets/Bewerbungsbanner.jpg"/>
                        <div class="row facts mb-5">
                            <div class="col-4">
                                <div class="number">9 +</div>
                                <div>{{ $t('main.experience')}}</div>
                            </div>
                            <div class="col-4">
                                <div class="number">15 +</div>
                                <div>{{ $t('main.frameworks')}}</div>
                            </div>
                            <div class="col-4">
                                <div class="number">100%</div>
                                <div>{{ $t('main.rightOne')}}</div>
                            </div>
                        </div>

                        <h1>{{ $t('main.aboutMe')}}</h1>
                        <p id="about">{{ $t('main.aboutText')}}</p>

                        <ExampleProjects id="exampleProjects" class="scrollAnchor"/>
                        <Timeline id="cv" class="scrollAnchor"/>
                        <ProgrammingLanguages id="programmingLanguages" class="scrollAnchor"/>
                        <CertificatesAndCourses/>
                        <Miscellaneous id="miscellaneous" class="scrollAnchor"/>
                        <h1>{{$t('main.contact')}}</h1>
                        <ContactLinks/>
                    </div>
                    <div class="col-lg-1 pt-4 d-none d-lg-block ">
                        <LargeNavbar class=" sticky-top"/>
            </div>   
                    <b-button class="unlink impressum" v-b-modal:[`modal-impressum`]>
                        <p >Impressum</p>
                    </b-button>
        
                     <b-modal :id="`modal-impressum`" body-bg-variant="dark" bodyTextVariant='light' size="xl" centered scrollable hide-footer hide-header>
                     <div class='impressum'><h1>Impressum</h1><p>Angaben gemäß § 5 TMG</p><p>Markus Schottenhammer <br> 
                        Am Hanfberg 6<br> 
                        93333 Bad Gögging <br> 
                        </p><p> <strong>Vertreten durch: </strong><br>
                        Markus Schottenhammer<br>
                        </p><p><strong>Kontakt:</strong> <br>
                        Telefon: 0176-95839023<br>
                        E-Mail: <a href='mailto:markus@bavarianbits.de'>markus@bavarianbits.de</a>
                    <br></p><p><strong>Haftungsausschluss: </strong><br><br><strong>Haftung für Inhalte</strong><br><br>
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br><br><strong>Haftung für Links</strong><br><br>
                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br><br><strong>Urheberrecht</strong><br><br>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br><br><strong>Datenschutz</strong><br><br>
                        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. <br>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. <br>
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.<br>
                        </p><br> 
                        Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a> der <a href="https://www.kanzlei-hasselbach.de/standorte/frankfurt/" rel="nofollow">Kanzlei Hasselbach, Frankfurt</a> </div>
                        </b-modal>
                </div>     
            </div>
        </div>   
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ExampleProjects from '@/components/ExampleProjects/ExampleProjects.vue';
import Timeline from '@/components/CV/Timeline.vue';
import ProgrammingLanguages from '@/components/ProgrammingLanguages/ProgrammingLanguages.vue';
import ContactLinks from '@/components/ContactLinks.vue';
import CertificatesAndCourses from '@/components/CertificatesAndCourses.vue';
import Miscellaneous from '@/components/Miscellaneous.vue';
import Navbar from '@/components/Navbar.vue';
import LargeNavbar from '@/components/LargeNavbar/LargeNavbar.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
    name: 'HomeView',
    metaInfo: {
      title: 'Portfolio Schottenhammer',
      //content: 'Portfolio von Markus Schottenhammer',
      meta: [
      { name: 'description', content: 'Software developer portfolio of Markus Schottenhammer from Germany. Computer Science student, IT specialist for application development' },
    ]
    },
    components: {
        ExampleProjects,
        Timeline,
        LargeNavbar,
        ProgrammingLanguages,
        ContactLinks,
        CertificatesAndCourses,
        Miscellaneous,
        Sidebar,
        Navbar,
    }, 
}
</script>

<style lang="sass" scoped> 
    //bootstrap lg is at 992px
    @media (max-width: 992px)
        .scrollAnchor
            scroll-margin-top: 250px

    .scrollable
        height: 100%
        overflow-y: auto
        overflow-x: hidden
    .impressum
        margin-top: 50px
    #about
        padding-bottom: 30px
        display: inline-block
        text-align: center
        max-width: 80%

    /** Global settings */
    .container-md
        background-color: #1e1e28

    /** Right "main" part*/
    .banner
        max-width: 100%
        margin: 15px 0px

    .facts
        padding-bottom: 15px
    .number
        font-size: 20px
</style>